<template>
  <div>
    <h5 class="mb-3">
      ตั้งค่ารับของรายตัว
      <span class="text-secondary" style="text-decoration: underline;">(เฉพาะงวด)</span>
      วันที่ <span class="text-primary">{{ roundDate }}</span>
      <small class="text-secondary ml-2">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
    </h5>

    <div>
      <ul class="nav nav-tabs">
        <li v-for="rate in rates" :key="rate._id" class="nav-item">
          <a class="nav-link" :class="[{'active':tabActiveRateId===rate._id}]" href="#" target="_self" @click="tabActiveRateId=rate._id">{{ rate.rateTitle }}</a>
        </li>
      </ul>

      <div id="sets-receive-numbers" class="row">
        <div v-for="bet in marketOpenBets" :key="bet.code" class="custom-col col-md-4">
          <SetsReceiveNumber :data="data" :rateId="tabActiveRateId" :bet="bet" @reload="reloadData" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { marketTypes } from "@/configs/market.config"
import moment from '@/helpers/moment'
import SetsReceiveNumber from './SetsReceiveNumber'

export default {
  name: 'SetsReceiveNumbers',
  components: {
    SetsReceiveNumber
  },
  props: ['data', 'rateId'],
  data() {
    return {
      tabActiveRateId: null
    }
  },
  watch: {
    rateId() {
      this.tabActiveRateId = this.rateId
    }
  },
  computed: {
    market() {
      return this.data?.market ?? {}
    },
    round() {
      return this.data?.round ?? {}
    },
    roundId() {
      return this.round._id
    },
    roundDate() {
      return moment(this.round?.roundDate?.date).format("DD/MM/YYYY")
    },
    rates() {
      return this.data?.rates ?? []
    },
    rate() {
      return this.rates.find((r)=>{
        return r._id === this.tabActiveRateId
      })
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.market?.openBets?.[o.code] && this.rate?.openBets?.[o.code]?.isAvailable
      })
    },
  },
  methods: {
    reloadData() {
      this.$emit('reload')
    }
  },
  mounted() {
    this.tabActiveRateId = this.rateId
  }
}
</script>
<style lang="scss">
#sets-receive-numbers {
  margin-right: -10px;
  margin-left: -10px;
  padding-top: 20px;
  padding-bottom: 20px;

  .custom-col {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
  }
}
</style>
