<template>
  <div>
    <table class="table table-bordered table-warning table-hover mb-0">
      <thead>
        <tr class="text-dark">
          <th width="25%" class="text-center">อัตราจ่าย</th>
          <th width="25%" class="text-center">ราคา<br />(บาท/ชุด)</th>
          <th width="25%" class="text-center">จำนวนรับของ<br />(ชุด/เลข)<br /><span class="text-danger">(0 = ไม่รับของ)</span></th>
          <th width="25%" class="text-center">ซื้อสูงสุดต่อครั้ง<br />(ชุด/เลข)<br /><span class="text-danger">(0 = ไม่รับของ)</span></th>
          <th v-if="isCompany" width="60" class="text-center">แก้ไข</th>
        </tr>
      </thead>
      <tbody class="alert-warning bg-light">
        <tr v-for="rate in rates">
          <td class="text-center text-primary">{{ rate.rateTitle }}</td>
          <td class="text-center">{{ settings[rate._id].price | amountFormat(2, '0.00') }}</td>
          <td class="text-center">
            <b-form-input
              v-if="isCompany"
              size="sm"
              :number="true"
              :disabled="!permSetting"
              class="text-center"
              v-model="input[rate._id]['receives']['setReceiveAmount']"
            />
            <span v-else>{{ settings[rate._id].receives.setReceiveAmount }}</span>
          </td>
          <td class="text-center">
            <b-form-input
            v-if="isCompany"
              size="sm"
              :number="true"
              :disabled="!permSetting"
              class="text-center"
              v-model="input[rate._id]['receives']['setMaxBet']"
            />
            <span v-else>{{ settings[rate._id].receives.setMaxBet }}</span>
          </td>
          <td v-if="isCompany" class="text-center">
            <b-button
              variant="info"
              size="sm"
              :disabled="!permSetting"
              @click="saveRow(rate._id)"
            >save</b-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import RoundService from "@/services/RoundService"

export default {
  name: 'SetsReceiveTable',
  props: ['data'],
  data() {
    return {
      input: {}
    }
  },
  computed: {
    roundId() {
      return this.data?.round?._id
    },
    market() {
      return this.data?.market ?? null
    },
    rates() {
      return this.data?.rates ?? []
    },
    settings() {
      return this.data.settings ?? {}
    },
    isCompany() {
      return this.$store.state.userProfile.role === 'Company'
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.RoundReceive === 'w'
    }
  },
  watch: {
    roundId() {
      this.input = JSON.parse(JSON.stringify(this.data.settings))
    }
  },
  methods: {
    saveRow(rateId) {
      const numberPattern = /^[0-9]+$/
      if(!numberPattern.test(this.input[rateId]['receives']['setReceiveAmount']) || !numberPattern.test(this.input[rateId]['receives']['setMaxBet'])) {
        return Swal.fire({
          title: 'ผิดพลาด!',
          text: `กรุณากรอกค่าให้ถูกต้อง`,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      this.save({
        roundId: this.roundId,
        rateIds: [rateId],
        receives: this.input[rateId]['receives']
      })
    },
    save(data) {
      RoundService.updateSetsReceive(data)
      .then((response)=>{
        if(response.success) {
          this.$emit('reload')
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ไม่สำเร็จ!',
          text: 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  created() {
    this.input = JSON.parse(JSON.stringify(this.data?.settings ?? {}))
  }
}
</script>
<style lang="scss" scoped>
.table {
  width: 1000px;
  max-width: 100%;

  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        line-height: 1;
        // padding: 5px;
      }
    }
  }
}
</style>
