<template>
  <div>
    <div class="close-time d-flex justify-content-between mb-2">
      <h6>เวลาเปิดรับ</h6>
      <h6 style="width: 300px" class="text-success">{{ companyOpenDate }}</h6>
    </div>
    <div class="close-time d-flex justify-content-between mb-2">
      <h6>เวลาปิดรับ</h6>
      <h6 style="width: 300px" class="text-danger">{{ companyCloseDate }}</h6>
    </div>
  </div>
</template>
<script>
import moment from '@/helpers/moment'

export default {
  name: 'SetsCloseTime',
  props: ['data'],
  data() {
    return {
      isShowModal: false
    }
  },
  computed: {
    companyOpenDate() {
      if(!this.data)
        return ''

      return moment(this.data?.round?.roundDate.open).format("DD/MM/YYYY HH:mm")
    },
    companyCloseDate() {
      if(!this.data)
        return ''

      return moment(this.data?.round?.roundDate.close).format("DD/MM/YYYY HH:mm")
    }
  }
}
</script>
<style lang="scss" scoped>
.close-time {
  width: 500px;
  max-width: 100%;

  h6 {
    margin-bottom: 0
  }
}
</style>
